import {
  Container,
  Row,
  Col
} from 'reactstrap';
import styles from './styles.module.scss';
import EditableText from "@/components/EditableText";
import clsx from "classnames";
import PropTypes from "prop-types";
import Link from "next/link"
import Sourceflow from '/public/site-assets/svg/sourceflow-white-logo.svg';
import MobileLogo from '/public/site-assets/svg/footer-logo.svg';
import DesktopLogo from '/public/site-assets/svg/white-logo.svg';
import FooterNavigation_1 from '@/.sourceflow/footer_navigation_1.json';
import FooterNavigation_2 from '@/.sourceflow/footer_navigation_2.json';
import FooterCopyrightNavigation from '@/.sourceflow/footer_copyright_navigation.json';
import FooterClientImages from '@/.sourceflow/footer_client_images.json';
import EditableImage from "@/components/EditableImage";
import sourceflowEnums from "@/sourceflow.enums";

import global from '@/.sourceflow/global.json';
import BaseObject from '@sourceflow-uk/sourceflow-sdk/base_object'
import { useLocale } from "@/hooks/useLocale";
import SocialLinks from '@/components/Shared/SocialLinks';
import { useEffect, useState } from 'react';

export default function Footer({
  className
}) {

  const locale = useLocale();
  const main_global = new BaseObject(global, `${locale}`).toJson();
  const [activeHeading, setActiveHeading] = useState(null);

  const handleHeadingClick = (index) => {
    setActiveHeading(activeHeading === index ? null : index);
  };

  return (
    <footer className={clsx(className, styles.root)}>
      <Container>
        <div className='wrapper d-lg-flex flex-wrap flex-lg-nowrap justify-content-between gap-5'>
          <div className='column'>
            <Link href={`/`} title={`${sourceflowEnums.seo.siteName}`} className='logo d-inline-block' aria-label={`${sourceflowEnums.seo.siteName}`}>
              <span className='d-none d-lg-block'><DesktopLogo /></span>
              <span className='d-block d-lg-none'><MobileLogo /></span>
            </Link>
            <div className='d-none d-lg-block'>
              <SocialLinks />
            </div>
          </div>
          {FooterNavigation_1?.category_values && (
            <div className='column' onClick={() => handleHeadingClick(1)}
            >
              <EditableText
                content={`Jobs by Location`}
                path={`footer.navigation.column_title_1`}
                global={main_global}
                tag={`div`}
                className={`mb-4 heading h2`}
              />
              <ul className={clsx("list-unstyled m-0", { 'active': activeHeading === 1 })}>
                {FooterNavigation_1?.category_values.map((post, index) => (
                  <li key={index}>
                    {post?.new_tab?.en ?
                      <a href={`${post.url.en}`} target="_blank" rel="noopener noreferrer">
                        {post.title.en}
                      </a> :
                      <Link href={`${post.url.en}`}>
                        {post.title.en}
                      </Link>
                    }
                  </li>
                ))}
              </ul>
            </div>
          )}
          {FooterNavigation_2?.category_values && (
            <div className='column' onClick={() => handleHeadingClick(2)}>
              <EditableText
                content={`Join us`}
                path={`footer.navigation.column_title_2`}
                global={main_global}
                tag={`div`}
                className={`mb-4 heading h2`}
              />
              <ul className={clsx("list-unstyled", { 'active': activeHeading === 2 })}>
                {FooterNavigation_2?.category_values.map((post, index) => (
                  <li key={index} className='mb-1'>
                    {post?.new_tab?.en ?
                      <a href={`${post.url.en}`} target="_blank" rel="noopener noreferrer">
                        {post.title.en}
                      </a> :
                      <a href={`${post.url.en}`} className=''>
                        {post.title.en}
                      </a>
                    }
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {FooterClientImages?.category_values && (
          <div className='div mt-5'>
            <ul className="list-unstyled credits d-flex flex-wrap align-items-center justify-content-center gap-4 justify-content-lg-between">
              {FooterClientImages?.category_values.map((post, index) => (
                <li key={index} >
                  {post.url?.en ?
                    <a href={`${post?.url.en}`} target="_self">
                      <EditableImage
                        className={`${post.url_slug}`}
                        img={post.image.en ? post.image.en : "/images/placeholders/user-profile.jpg"}
                        size={`150x`}
                        alt={`${post.title.en}`}
                      />
                    </a> :
                    <EditableImage
                      className={`${post.url_slug}`}
                      img={post.image.en ? post.image.en : "/images/placeholders/user-profile.jpg"}
                      size={`150x`}
                      alt={`${post.title.en}`}
                    />}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className='d-block d-lg-none'>
          <SocialLinks
            className={`pb-0 pt-5 mobile-socials`}
          />
        </div>
      </Container>
      <div className='copyright-wrapper pt-4'>
        <Container className=''>
          <div className="d-flex flex-wrap justify-content-between py-3 copyrights">
            <div className="d-flex justify-content-center justify-content-lg-start flex-wrap align-items-center gap-3 gap-lg-4 copyright-links">
              <p className="m-0 trademark">© Tradewind Recruitment {new Date().getFullYear()}</p>
              {FooterCopyrightNavigation?.category_values && (
                FooterCopyrightNavigation?.category_values.map((post, index) => (
                  <p className='m-0 links'>
                    {post?.new_tab?.en ?
                      <a href={`${post.url.en}`} target="_blank" rel="noopener noreferrer">
                        {post.title.en}
                      </a> :
                      <Link href={`${post.url.en}`}>
                        {post.title.en}
                      </Link>
                    }
                  </p>
                ))
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center site-by mt-2 mt-lg-0">
              <p className="mb-0 me-1">Site by </p>
              <Link href="https://www.sourceflow.co.uk/" target="_blank" aria-label="SourceFlow" className='sourceflow'>
                <Sourceflow />
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </footer >
  )
}

Footer.defaultProps = {
  pathPrefix: "",
  className: "py-5",
};

Footer.propTypes = {
  pathPrefix: PropTypes.string,
  className: PropTypes.string,
};