import { SourceFlowHeadDefault } from "@sourceflow-uk/sourceflow-head";
import metaObject from "../.sourceflow/metadata.json";
import SourceFlowTracker from '@sourceflow-uk/sourceflow-tracker';
import CookieConsent from "@/components/Shared/CookieConsent";
import sourceflowEnums from "@/sourceflow.enums";
import Layout from '@/components/layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../styles/styles.scss';

//// -- uncomment this to import GoogleAnalytics and/or GoogleTagManager
import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import Script from "next/script";

/**
 *
 * @param Component
 * @param pageProps
 * @returns {JSX.Element}
 * @constructor
 */
export default function App({ Component, pageProps }) {

	return (

		<Layout>
			<SourceFlowHeadDefault
				metaObject={metaObject}
				siteName={sourceflowEnums.seo.siteName}
				titleTemplate={sourceflowEnums.seo.titleTemplate}
				images={sourceflowEnums.seo.images}
				twitter={sourceflowEnums.seo.twitter}
			/>

			<Component {...pageProps} />
			<SourceFlowTracker />
			<GoogleAnalytics gaId="G-JKQPS8LCP5" />
			<CookieConsent>
				<SourceFlowTracker />
				{/* <GoogleAnalytics gaId="JKQPS8LCP5" /> */}
				{/* <GoogleTagManager gtmId="CUSTOMER_GMT_ID" /> */}
			</CookieConsent>
		</Layout >
	)
}

// -- Reference -- //
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflow-head
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflow-tracker
